import {
  DeviceCategoryEnum,
  DeviceStatus,
  OperatingSystemEnum,
} from '~/constants/enums';

export function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function convertUtcToLocalDate(utc: string | null) {
  if (!utc) return '';

  return new Date(utc).toLocaleDateString();
}
export function formatMonthDayYearFromSeconds(seconds: number) {
  const date = new Date(seconds * 1000);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export function formatIpAddress(ip: string) {
  return ip.split('/')[0];
}

export function formatPriceFromCentsToDollars(price: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(price / 100);
}
export function formatPrice(price: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(price);
}

export function formatBillingFrequency(gpuCount: number | null) {
  if (Boolean(gpuCount) === true) {
    return 'per card-hour';
  }
  return 'per hour';
}

export function formatCreditCardName(brand: string) {
  switch (brand.toLowerCase()) {
    case 'amex':
      return 'American Express';
    case 'diners':
      return 'Diners Club';
    case 'discover':
      return 'Discover';
    case 'eftpos_au':
      return 'EFTPOS';
    case 'jcb':
      return 'JCB';
    case 'mastercard':
      return 'Mastercard';
    case 'unionpay':
      return 'UnionPay';
    case 'visa':
      return 'Visa';
    default:
      return 'Card';
  }
}

export function formatOperatingSystem(os: string | null) {
  switch (os) {
    case 'ubuntu2004':
      return 'Ubuntu 20.04';
    case 'ubuntu2204':
      return 'Ubuntu 22.04';
    case 'ubuntu-20-04':
      return 'Ubuntu 20.04';
    case 'ubuntu-22-04':
      return 'Ubuntu 22.04';
    default:
      return os;
  }
}

export function getUserInitials(name: string) {
  const [firstName, lastName] = name.split(' ');
  return `${firstName[0].toUpperCase()}${
    lastName ? lastName[0].toUpperCase() : ''
  }`;
}

export function getMarketplaceStatusTextColor(status: string) {
  switch (status) {
    case 'on demand':
      return 'text-green';
    case 'reserve':
      return 'text-blue-light';
    case 'preorder':
      return 'text-blue';

    default:
      return 'text-blue';
  }
}

export function getDeviceStatusTextColor(status: string) {
  switch (status) {
    case DeviceStatus.Provisioned:
      return 'text-emerald-500';
    case DeviceStatus.Active:
      return 'text-emerald-500';
    case DeviceStatus.Queued:
      return 'text-yellow-500';
    case DeviceStatus.Provisioning:
      return 'text-yellow-500';
    case DeviceStatus.Rebooting:
      return 'text-yellow-500';
    case DeviceStatus.Inventory:
      return 'text-blue';
    case DeviceStatus.Staged:
      return 'text-blue';

    case DeviceStatus.Decommissioning:
      return 'text-orange-500';
    case DeviceStatus.Failed:
      return 'text-red-500';

    default:
      return 'text-muted-foreground';
  }
}

export function getStatusPulseEffect(status: string) {
  switch (status) {
    case DeviceStatus.Provisioning:
      return 'animate-pulse';
    case DeviceStatus.Rebooting:
      return 'animate-pulse';
    case DeviceStatus.Decommissioning:
      return 'animate-pulse';

    default:
      return '';
  }
}

export function formatCategoryEnum(category: string) {
  // Strapi does not allow enums to start with numbers, so in order for the filter function to work properly, we need to format the string
  switch (category) {
    case 'rtx-3090':
      return DeviceCategoryEnum.Values['3090'];
    case 'rtx-4090':
      return DeviceCategoryEnum.Values['4090'];
    default:
      return category;
  }
}

export function formatCategoryTitle(category: string) {
  return category.toUpperCase();
}

export function formatSizeGBToTB(sizeInGB?: number | null) {
  if (!sizeInGB || sizeInGB === 0) return null;
  const TB_THRESHOLD = 1000;

  if (sizeInGB >= TB_THRESHOLD) {
    const sizeInTB = sizeInGB / TB_THRESHOLD;
    return Number.isInteger(sizeInTB)
      ? `${sizeInTB} TB`
      : `${sizeInTB.toFixed(2)} TB`;
  } else {
    return Number.isInteger(sizeInGB)
      ? `${sizeInGB} GB`
      : `${sizeInGB.toFixed(2)} GB`;
  }
}
